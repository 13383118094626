import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@/components/common/ui/buttons/Button'
import Notice from '@/components/common/ui/Notice'
import Input from '@/components/common/ui/forms/Input'
import TextArea from '@/components/common/ui/forms/TextArea'
import { guestbookEntity } from '../store/root'
import * as filestack from 'filestack-js'
import "react-responsive-carousel/lib/styles/carousel.min.css"
import ReCAPTCHA from "react-google-recaptcha-enterprise"
import { PhotoIcon, FireIcon } from '@heroicons/react/24/solid'
import Toggle from '@/components/common/ui/forms/Toggle'
import { sendLegacyGtm, str, t, lang } from '@/components/common/Helpers'
import { PulseLoader } from 'react-spinners'

export default function GuestbookForm() {

    const {
        guestbookList,
        guestbookFetchAllLoading,
        guestbookCreateError
    } = useSelector(state => state.guestbookReducer)
    const { obituary } = useSelector(state => state.obituaryReducer)

    const locale = lang

    const [candleMode, setCandleMode] = useState(false)
    const [photoMode, setPhotoMode] = useState(false)
    const [subscribe, setSubscribe] = useState(true)

    const recaptchaRef = useRef()
    const methods = useForm()
    methods.watch(['assets', 'message', 'name', 'email'])

    const dispatch = useDispatch()

    const save = (data) => {
        if (window.gck) {
            recaptchaRef.current.execute().then((value) => {
                dispatch(guestbookEntity.services.create({ ...data, ...{ recaptcha_token: value } }, { return: true }))
            })
        } else {
            dispatch(guestbookEntity.services.create(data, { return: true }))
        }
    }

    const cookiesEnabled = navigator.cookieEnabled

    /* Init filestack client */
    const client = cookiesEnabled && obituary?.settings?.guestbook_images ? filestack.init(import.meta.env.VITE_FILESTACK_KEY) : null

    const choosePhotos = () => {
        /** @var {PickerOptions} **/
        const options = {
            fromSources: ['local_file_system', 'instagram', 'facebook', 'imagesearch'],
            accept: ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp'],
            maxFiles: 5,
            onFileUploadFinished: file => {
                let assets = methods.getValues().assets ? methods.getValues().assets : []
                assets.push(file)
                methods.setValue('assets', assets)

                client.picker().close()
            },
            lang: str(locale).before('_').before('-').toString(),
        }

        client.picker(options).open()
    }

    useEffect(() => {
        methods.setValue("has_candle", candleMode)
    }, [candleMode])

    useEffect(() => {
        methods.setValue('subscribe', subscribe)
    }, [subscribe])

    return (
        <FormProvider {...methods}>
            <form method="post" className="" onSubmit={methods.handleSubmit(save)}>
                {window.gck &&
                    <ReCAPTCHA
                        size="invisible"
                        ref={recaptchaRef}
                        sitekey={window.gck}
                    />
                }
                {guestbookCreateError &&
                    <div className="mb-4">
                        <Notice description={guestbookCreateError.message} type="warning" />
                    </div>
                }

                <div className="hidden">
                    <Input name="_hp_text" type="text" value="" tabIndex="-1" />
                    <Input name="_hp_time" type="text" defaultValue={Date.now()} readOnly />
                </div>

                <div className="flex">
                    {candleMode &&
                        <div className="flex-none mr-4">
                            <img loading='lazy' src={`${import.meta.env.VITE_APP_URL}/img/guestbook/candles.svg`} className="w-24 h-24" />
                        </div>
                    }
                    <div className="flex-grow">

                        <div className="mb-4">
                            {guestbookFetchAllLoading ? (
                                <PulseLoader color={"#ccc"} size={12} margin={10} />
                            ) : (
                                <Input name="name" required placeholder={t.trans('guestbook.your-name')} />
                            )}
                        </div>
                        {methods.getValues().name &&
                            <>
                                <div className="mb-4">
                                    <TextArea name="message" placeholder={guestbookList?.data?.length > 0 ? t.trans('guestbook.placeholder') : t.trans('guestbook.placeholder-first')} />
                                </div>
                                <div className="mb-4">
                                    <Input name="email" type="email" placeholder={t.trans('guestbook.your-email')} />
                                </div>
                                {methods.getValues().email &&
                                    <div className="mb-4">
                                        <Toggle label={t.trans('guestbook.subscribe')} state={subscribe} action={setSubscribe} />
                                    </div>
                                }
                            </>
                        }
                        {photoMode &&
                            <div className="mb-4">
                                {methods.getValues().assets ? (

                                    <div className="flex gap-4 p-4 flex-wrap justify-center bg-gradient-to-b from-gray-600 to-gray-400">
                                        {methods.getValues().assets.map(asset => (
                                            <img loading='lazy' key={asset.uploadId} className="rounded-md h-32" src={asset.url} alt={'asset'} />
                                        ))}
                                    </div>

                                ) : (
                                    <div className="border-2 border-dashed border-gray-300 rounded-lg items-center justify-center flex p-4">
                                        <div className="space-y-1 text-center" onClick={choosePhotos}>
                                            <svg
                                                className="mx-auto h-12 w-12 text-gray-400"
                                                stroke="currentColor"
                                                fill="none"
                                                viewBox="0 0 48 48"
                                                aria-hidden="true"
                                            >
                                                <path
                                                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                    strokeWidth={2}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                            <div className="text-sm text-gray-600">
                                                <p
                                                    className="text-center cursor-pointer bg-white rounded-md font-medium text-blue-600 hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
                                                >
                                                    <span>{t.trans('guestbook.select-photos')}</span>
                                                </p>
                                            </div>
                                            <p className="text-center text-xs text-gray-500">PNG, JPG, GIF {t.trans('guestbook.up-to')}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>

                <div className="grid border-t border-gray-200 pt-5 mt-2 grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
                    <div className="grid md:flex grid-cols-2 gap-2">
                        {cookiesEnabled && obituary?.settings?.guestbook_images &&
                            <Button label={t.trans('guestbook.photos')} Icon={PhotoIcon} color={photoMode ? "green" : "white"} onClick={() => {
                                setPhotoMode(true)
                                choosePhotos()
                                if (photoMode) {
                                    return
                                }
                                sendLegacyGtm('click_cta', {
                                    'cta': {
                                        'name': 'clicked_add_photo',
                                        'type': 'button',
                                    }
                                })
                            }} iconClassName="hidden lg:block" />
                        }
                        {obituary?.settings?.guestbook_candles &&
                            <Button label={t.trans('guestbook.light')} Icon={FireIcon} color={candleMode ? "yellow" : "white"} onClick={() => {
                                setCandleMode(!candleMode)
                                if (candleMode) {
                                    return;
                                }
                                sendLegacyGtm('click_cta', {
                                    'cta': {
                                        'name': 'clicked_light_a_candle',
                                        'type': 'button',
                                    }
                                })
                            }} iconClassName="hidden lg:block" />
                        }
                    </div>

                    <div className="flex justify-end">
                        <Button onClick={() => {
                            sendLegacyGtm('click_cta', {
                                'cta': {
                                    'name': 'clicked_submitted_message',
                                    'type': 'button',
                                }
                            })
                        }} className="w-full md:w-auto" label={t.trans('guestbook.submit')} type="submit" />
                    </div>
                </div>
            </form>
        </FormProvider>
    )
}
